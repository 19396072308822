import config from '@/config';
import fetch from '@/fetch';

const BASE_URL = `${config.url.api.transfeera}/med/infractions`;

export default {
  getInfractionsList: ({
    page_cursor,
    page_size = 10,
    analysis_status,
    transaction_id,
    payer_tax_id,
    infraction_date__gte,
    infraction_date__lte
  }) => {
    const query = new URLSearchParams({ page_size });

    for (const [key, value] of Object.entries({
      analysis_status,
      infraction_date__gte,
      infraction_date__lte,
      transaction_id,
      payer_tax_id,
      page_cursor
    })) {
      if (value) {
        query.append(key, value);
      }
    }
    return fetch(`${BASE_URL}?${query}`);
  },

  getInfraction: infraction_id => {
    return fetch(`${BASE_URL}/${infraction_id}`);
  },

  batchAnalysis: ({ infraction_ids, analysis }) => {
    const body = {
      infraction_ids,
      analysis
    };
    return fetch(`${BASE_URL}/analysis`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  },

  analyzeInfraction: ({ infraction_id, analysis }) => {
    const formData = new FormData();
    formData.append('analysis', analysis.analysisDecision);
    formData.append('analysis_description', analysis.analysisDescription);
    for (let i = 0; i < analysis.files.length; i++) {
      formData.append('attachments', analysis.files[i], analysis.files[i].name);
    }
    return fetch(
      `${BASE_URL}/${infraction_id}/analysis`,
      {
        method: 'POST',
        'Content-Type': undefined,
        body: formData
      },
      { isFile: true }
    );
  },

  getPendingInfractionReviews: () => {
    return fetch(`${BASE_URL}/pending`);
  },

  exportInfractions: ({ analysis_status__in, search_term, infraction_date__gte, infraction_date__lte }) => {
    const body = {
      analysis_status__in,
      search_term,
      infraction_date__gte,
      infraction_date__lte
    };
    return fetch(`${BASE_URL}/export`, {
      method: 'POST',
      body: JSON.stringify(body)
    });
  }
};

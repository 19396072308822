<template>
  <tr-form
    ref="form"
    :on-valid-submit="submitForm"
  >
    <tr-page-section
      title="Dados do cliente referente à análise"
      class="no-margin"
    >
      <div class="row align-items-center">
        <div class="col-lg-8 col-md-12">
          Descreva abaixo sua defesa para a notificação de infração (obrigatório)
          <textarea
            v-model="infractionAnalysis.analysisDescription"
            class="textarea"
            rows="5"
            maxlength="2000"
            required
            :readonly="isReadOnly"
          />
          <small class="text-muted">
            {{ characterCount }}/2000 caracteres
          </small>
        </div>
        <div
          class="col-lg-4 col-md-12"
        >
          <div
            v-if="!isReadOnly"
            class="vertical-offset2"
          >
            <file-uploader @files-changed="handleFiles" />
          </div>
          <div>
            {{ isReadOnly ? "Anexos para comprovação da defesa" : "" }}
            <div
              v-if="infractionData.files.length"
              class="file-list"
            >
              <div
                v-for="(file, index) in infractionData.files"
                :key="index"
              >
                <bg-link
                  :to="file.signed_url"
                  class="file-item"
                >
                  <bg-text
                    v-tooltip.top-center="file.name"
                    weight="bold"
                    type="body-md"
                    color="blue-semi-dark"
                  >
                    {{ file.name.length > 30 ? middleTruncateWithEllipsis(file.name, 30) : file.name }}
                  </bg-text>
                  <bg-icon
                    size="sm"
                    name="arrow-right"
                    color="blue-semi-dark"
                  />
                </bg-link>
              </div>
            </div>
            <div
              v-if="isReadOnly && !infractionData.files.length"
              class="text-muted d-flex flex-column align-items-center vertical-offset3"
            >
              Nenhum anexo adicionado
            </div>
          </div>
        </div>
      </div>
    </tr-page-section>
    <tr-page-section title="Parecer do cliente">
      <div
        class="row"
        style="margin-left: 0px"
      >
        <div class="text-center bottom-offset3">
          <div class="bottom-offset2">
            <strong>Qual sua decisão sobre esta notificação?</strong>
          </div>
          <div class="d-flex">
            <div
              v-for="analysisDecision in analysisDecisionOptions"
              :key="analysisDecision.value"
              class="inline-flex"
            >
              <tr-text-field
                v-model="infractionAnalysis.analysisDecision"
                name="analysis-decision"
                type="radio"
                :required="true"
                :disabled="isReadOnly"
                :radio-value="analysisDecision.value"
              >
                <template #label>
                  <span>{{ analysisDecision.name }}</span>
                </template>
              </tr-text-field>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="!isReadOnly"
        class="row"
        style="margin-left: 0px"
      >
        <div class="d-flex pull-left text-center">
          <bg-text
            is="label"
            for="accept-terms"
            type="body-md"
            style="width: 65%"
          >
            Ao clicar em 'Confirmar', declaro que as informações prestadas são verdadeiras. Estou ciente que esta ação é irreversível e os dados não poderão ser alterados posteriormente.
          </bg-text>
        </div>
      </div>
    </tr-page-section>
    <tr-footer-buttons-container
      class="inline-block"
    >
      <bg-button
        v-if="isReadOnly"
        class="pull-left"
        variant="outline"
        @click="emitBack"
      >
        Voltar
      </bg-button>
      <bg-button
        v-if="!isReadOnly"
        class="pull-left"
        variant="outline"
        @click="emitShowAnalysisForm"
      >
        Descartar análise
      </bg-button>
      <bg-button
        v-if="!isReadOnly"
        class="pull-right"
        type="submit"
        :disabled="isAnalysisProcessing || infractionAnalysis.analysisDecision === ''"
      >
        {{ isAnalysisProcessing ? 'Processando...' : 'Confirmar' }}
      </bg-button>
    </tr-footer-buttons-container>
  </tr-form>
</template>

<script>
import FileUploader from '@/apps/contestations/infractions/components/FileUploader.vue';
import { BgText, BgButton, BgLink, BgIcon } from '@transfeeradev/bridge';
import { formatDateTime } from '@/commons/formatters/formatDate';
import { middleTruncateWithEllipsis } from '@/commons/formatters/string';
import { analyzeInfraction } from '@/apps/contestations/infractions/services/infractions';

export default {
  name: 'infraction-analysis-form',
  components: {
    FileUploader,
    BgText,
    BgButton,
    BgLink,
    BgIcon
  },
  props: {
    infractionData: {
      type: Object
    },
    isReadOnly: {
      type: Boolean
    }
  },
  emits: ['show-analysis-form', 'back', 'analysis-submitted'],
  setup(props) {
    return {
      props,
      formatDateTime,
      middleTruncateWithEllipsis
    };
  },
  data() {
    return {
      infractionAnalysis: {
        analysisDescription: this.isReadOnly ? this.infractionData.analysis_description : '',
        analysisDecision: this.isReadOnly ? this.infractionData.analysis_status : '',
        files: []
      },
      analysisDecisionOptions: [
        { name: 'Aprovar', value: 'accepted' },
        { name: 'Reprovar', value: 'rejected' }
      ],
      loading: false
    };
  },
  computed: {
    isAnalysisProcessing() {
      return this.loading;
    },
    characterCount() {
      return this.infractionAnalysis.analysisDescription ? this.infractionAnalysis.analysisDescription.length : 2000;
    }
  },
  methods: {
    handleFiles(files) {
      this.infractionAnalysis.files = files;
    },
    async submitForm() {
      this.loading = true;
      const { analysisDescription, analysisDecision, files } = this.infractionAnalysis;
      try {
        if (analysisDescription.length < 10) {
          this.toastError('A descrição da análise deve ter pelo menos 10 caracteres.');
          this.loading = false;
          return;
        }
        await this.processRequest({ analysisDescription, analysisDecision, files });
        this.emitAnalysisSubmitted();
        this.emitBack();
        window.dispatchEvent(new Event('refreshPendingInfractions'));
      } catch (error) {
        this.toastError('Ocorreu um erro ao processar a análise da infração.');
      } finally {
        this.loading = false;
      }
    },
    async processRequest(data) {
      await analyzeInfraction(this.infractionData.id, data);
    },
    emitShowAnalysisForm() {
      this.$emit('show-analysis-form', false);
    },
    emitBack() {
      this.$emit('back');
    },
    emitAnalysisSubmitted() {
      this.$emit('analysis-submitted');
    },
    toastError(message) {
      this.$toaster.error(message);
    }
  }
};
</script>

<style scoped>
.margin-bottom {
  margin-bottom: 30px;
}

.margin-top {
  margin-top: 10px;
}

.document-container {
  align-items: center;
  gap: 4px;
  margin-top: 8px;
}

.inline-flex {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

.vertical-offset2 {
  margin-top: 16px;
}

.textarea {
  font-family: inherit;
  border: 1px solid var(--bg-grey-semi-light);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: var(--bg-grey-white);
  padding: 16px;
  resize: vertical;
  width: 100%;

  &::placeholder {
    color: var(--bg-grey-semi-light);
  }

  &[readonly] {
    color: var(--bg-grey-semi-dark);
    background-color: var(--bg-grey-light);
  }
}

.text-muted {
  color: #6c757d;
  font-size: 0.875rem;
}

.file-list {
  margin-top: 1rem;
}

.file-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}
</style>

// @ts-check
import userResource from '@/commons/resources/companyUser';
import { getCurrentInstance, readonly } from 'vue';
import { usePagination } from '@/commons/composables/usePagination';
import { getMessage } from '@/commons/services/error';

/**
 *
 * @param {Object} options
 * @param {ComputedRef<string>} [options.searchText]
 * @param {ComputedRef<string>} [options.appFilter]
 * @param {ComputedRef<string>} [options.profileFilter]
 */
export const useUsers = ({ searchText, appFilter, profileFilter } = {}) => {
  const { $toaster } = getCurrentInstance().appContext.config.globalProperties;

  const { data, loading, paginationProps, refresh } = usePagination(options => {
    return userResource
      .getAll({
        ...options,
        search: searchText?.value,
        application: appFilter?.value,
        profile: profileFilter?.value
      })
      .catch(error => {
        $toaster.error(getMessage(error));
      });
  });

  return {
    refresh,
    loadingUsers: readonly(loading),
    users: readonly(data),
    paginationProps: paginationProps
  };
};

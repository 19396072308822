<template>
  <tr-dialog
    ref="modal"
    :unique-name="true"
    name="infractions-export-dialog"
    title="Exportar infrações para CSV"
    :has-its-own-buttons="true"
    :on-close="close"
  >
    <div v-if="!isRequestFinished">
      <bg-text
        type="body-md"
        color="grey-dark"
      >
        Ao confirmar, as infrações serão exportadas para um arquivo CSV conforme os filtros da listagem de infrações
        abaixo.
      </bg-text>
      <div
        style="display: flex; justify-content: center"
        class="vertical-offset2"
      >
        <table role="presentation">
          <tbody>
            <tr
              v-for="filter in exportFilters"
              :key="filter.label"
            >
              <td
                v-if="filter.displayValue"
                style="padding-right: 10px"
              >
                {{ filter.label }}:
              </td>
              <td
                v-if="filter.displayValue"
                style="font-weight: bold"
              >
                {{ filter.displayValue }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else>
      <bg-text
        type="body-md"
        color="grey-dark"
      >
        O arquivo CSV está sendo gerado. Você será notificado via e-mail quando o download estiver disponível.
      </bg-text>
    </div>
    <tr-dialog-buttons-container>
      <bg-button
        class="pull-left"
        variant="outline"
        @click="close()"
      >
        Voltar
      </bg-button>
      <bg-button
        v-if="!isRequestFinished"
        size="md"
        class="pull-right"
        :disabled="isInfractionsExportProcessing"
        @click="infractionsExport()"
      >
        {{ isInfractionsExportProcessing ? 'Processando...' : `Exportar` }}
      </bg-button>
    </tr-dialog-buttons-container>
  </tr-dialog>
</template>

<script setup>
import { ref } from 'vue';
import { BgText, BgButton } from '@transfeeradev/bridge';
import { exportInfractions } from '@/apps/contestations/infractions/services/infractions';
import { useErrorHandler } from '@/commons/composables/useErrorHandler';

const { handleError } = useErrorHandler();

const props = defineProps({
  exportFilters: {
    type: Object,
    required: true
  }
});

const modal = ref();
const isInfractionsExportProcessing = ref(false);
const isRequestFinished = ref(false);

const open = () => {
  modal.value.open();
  setRequestStatus(false);
};

const close = () => {
  modal.value.close();
  setRequestStatus(false);
};

const infractionsExport = async () => {
  isInfractionsExportProcessing.value = true;
  try {
    await exportInfractions({
      infractionStartDate: props.exportFilters.infractionStartDate.requestValue,
      infractionEndDate: props.exportFilters.infractionEndDate.requestValue,
      searchTerm: props.exportFilters.searchTerm.requestValue,
      infractionAnalysisStatus: props.exportFilters.infractionAnalysisStatus.requestValue
    });
  } catch (error) {
    handleError(error, 'Ocorreu um erro ao tentar exportar as infrações');
    setRequestStatus(false);
  } finally {
    isInfractionsExportProcessing.value = false;
    setRequestStatus(true);
  }
};

const setRequestStatus = status => {
  isRequestFinished.value = status;
};

defineExpose({
  open,
  close
});
</script>

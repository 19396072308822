<template>
  <tr-page>
    <tr-page-header
      title="Suas transferências"
      :on-search="onSearchByText"
      :hide-button-for-roles="hideNewBatchActionForRoles"
      search-placeholder="Ag., conta, valor ou lote"
      :on-add-click="openCreateBatchModal"
    >
      <template #left-filter>
        <transfer-status-dropdown-filter-container :on-search="onSearchByStatus" />
      </template>
      <template #center-filter>
        <tr-month-picker-button :on-filter="onSearchByDatePeriod" />
      </template>
      <template #right-filter>
        <bank-dropdown-filter-container :on-search="onSearchByBank" />
      </template>
    </tr-page-header>
    <tr-page-section :no-margin="true">
      <tr-loading
        v-if="isLoading"
        classes="center-h-and-v width-100 vertical-offset5"
      />
      <tr-empty-search-result
        v-if="!shouldShowBlankslate && !hasTransfers && !isLoading"
        text="Nenhuma transferência foi encontrada na sua busca"
      />
      <transfer-first-use-blankslate v-if="shouldShowBlankslate && !hasTransfers && !isLoading" />
      <transfers-table
        v-if="hasTransfers && !isLoading"
        :on-change="search"
        :transfers="transfers"
        @on-transfer-checked="onTransferChecked"
      />
      <div class="is-flex flex-end vertical-offset3">
        <tr-button
          v-if="hasTransfers && !isLoading"
          id="reports-dropdown"
          variant="raised"
          label="Exportar"
          color="primary"
          class="horizontal-offset2"
          size="medium"
          dropdown="true"
        >
          <tr-dropdown-item
            key="csv"
            :on-item-click="createCSVPayoutReport"
          >
            Relatório CSV
          </tr-dropdown-item>
        </tr-button>
        <tr-button
          v-if="hasTransfers && !isLoading"
          label="Baixar selecionados"
          variant="outlined"
          class="horizontal-offset2"
          color="secondary"
          :on-button-click="downloadReceipts"
          :disabled="!checkedTransfers.length"
        />
        <tr-button
          v-if="hasTransfers && !isLoading"
          label="Baixar todos"
          variant="raised"
          class="horizontal-offset2"
          color="primary"
          :on-button-click="downloadAllReceipts"
        />
      </div>
      <tr-numeric-pagination
        v-if="!isLoading"
        class="transfer-pagination"
        :current-page="currentPage"
        :page-size="transfersPagination.itemsPerPage"
        :total="transfersPagination.totalItems"
        :on-current-page-change="onCurrentPageChange"
      />
    </tr-page-section>
    <download-receipt-dialog
      ref="downloadReceiptDialog"
      :batch-type="batchType.TRANSFER"
      :item-ids="transfersToDownload"
    />
    <download-receipt-error-dialog
      ref="downloadReceiptErrorDialog"
      :message="downloadReceiptErrorMessage"
    />
    <download-without-receipt-dialog ref="downloadWithoutReceiptDialog" />
    <batch-fullscreen-modal
      ref="batchFullscreenModal"
      :unique-name="true"
      :type="batchType.TRANSFER"
      :on-change="search"
    />
  </tr-page>
</template>

<script>
import DownloadReceiptDialog from '@/apps/payments/payments/batch/components/presentational/DownloadReceiptDialog';
import DownloadReceiptErrorDialog from '@/apps/payments/payments/batch/components/presentational/DownloadReceiptErrorDialog';
import DownloadWithoutReceiptDialog from '@/apps/payments/payments/batch/components/presentational/DownloadWithoutReceiptDialog';
import TransfersTable from '@/apps/payments/payments/transfer/components/presentational/TransfersTable';
import TransferStatusDropdownFilterContainer from '@/apps/payments/payments/transfer/components/container/TransferStatusDropdownFilterContainer';
import BankDropdownFilterContainer from '@/apps/payments/payments/bank/components/container/BankDropdownFilterContainer';
import TransferFirstUseBlankslate from '@/apps/payments/payments/transfer/components/presentational/TransferFirstUseBlankslate';
import transferResource from '@/apps/payments/payments/transfer/resources/transfer';
import loggedUserResource from '@/commons/resources/loggedUser';
import BatchFullscreenModal from '@/apps/payments/payments/batch/pages/BatchFullscreenModal';
import moment from '@transfeeradev/moment';
import checkpointResource from '@/apps/payments/resources/checkpoint';
import checkpoint from '@/commons/constants/checkpoint';
import { roles, batchType } from '@transfeeradev/api-enums';
import { getMessage } from '@/commons/services/error';
import { useTour } from '@/commons/composables/useTour';

export default {
  name: 'transfers-page',
  components: {
    TransfersTable,
    TransferStatusDropdownFilterContainer,
    BankDropdownFilterContainer,
    DownloadReceiptDialog,
    DownloadReceiptErrorDialog,
    DownloadWithoutReceiptDialog,
    TransferFirstUseBlankslate,
    BatchFullscreenModal
  },
  data: () => ({
    initialDate: moment().startOf('month'),
    endDate: moment().endOf('month'),
    searchTerm: '',
    currentPage: 0,
    isLoading: false,
    transfersPagination: {},
    transfers: [],
    searchStatus: undefined,
    searchBank: undefined,
    shouldShowBlankslate: false,
    hideNewBatchActionForRoles: roles.appRoles.ANALYST,
    batchType,
    downloadReceiptErrorMessage: '',
    checkedTransfers: [],
    transfersToDownload: []
  }),
  computed: {
    hasTransfers() {
      return Boolean(this.transfers && this.transfers.length);
    }
  },
  created() {
    this.refreshBlankslate();
    return this.search();
  },
  mounted() {
    const { defineSteps } = useTour({
      checkpointId: checkpoint.PAYOUT_REPORT_BUTTON_VIEWED,
      elementSelectors: ['#reports-dropdown']
    });

    defineSteps(
      ([step1El]) => [
        {
          element: step1El,
          title: 'Novo relatório de transferências',
          intro:
            'Agora você pode gerar um relatório de transferências no formato CSV com os mesmos filtros disponíveis na tela.'
        }
      ],
      {
        doneLabel: 'Entendi'
      }
    ).start();
  },
  methods: {
    search(resetPagination = true) {
      this.isLoading = true;

      if (resetPagination) {
        this.currentPage = 0;
      }

      return transferResource
        .getFiltered(
          this.initialDate,
          this.endDate,
          this.currentPage,
          this.searchTerm,
          this.searchStatus,
          this.searchBank
        )
        .then(
          response => {
            this.isLoading = false;
            this.checkedTransfers = [];

            if (response) {
              this.transfersPagination = response.metadata.pagination;
              this.transfers = response.data || [];
            } else {
              this.transfersPagination = {};
              this.transfers = [];
            }

            if (this.transfers.length) {
              this.shouldShowBlankslate = false;
            } else if (this.shouldShowBlankslate) {
              this.refreshBlankslate();
            }
          },
          error => this.$toaster.error(getMessage(error))
        );
    },
    refreshBlankslate() {
      transferResource.hasBlankslate().then(
        response => {
          this.shouldShowBlankslate = response.data;
        },
        error => this.$toaster.error(getMessage(error))
      );
    },
    showBatchFullscreenModal() {
      this.$refs.batchFullscreenModal.open();
    },
    onSearchByText(searchTerm) {
      this.searchTerm = searchTerm;
      this.search();
    },
    onSearchByStatus(searchStatus) {
      this.searchStatus = searchStatus;
      this.search();
    },
    onSearchByBank(searchBank) {
      this.searchBank = searchBank;
      this.search();
    },
    onSearchByDatePeriod(initialDate, endDate) {
      this.initialDate = initialDate;
      this.endDate = endDate;
      this.search();
    },
    onCurrentPageChange(newCurrentPage) {
      this.currentPage = newCurrentPage;
      this.search(false);
    },
    onTransferChecked(transferIds) {
      this.checkedTransfers = transferIds;
    },
    openCreateBatchModal() {
      const loggedUser = loggedUserResource.get();
      if (loggedUser.company.acceptedContract) {
        checkpointResource
          .create(checkpoint.OPENED_TRANSFER_CREATION_FROM_TRANSFERS_PAGE)
          .catch(error => this.$toaster.error(getMessage(error)));
        this.showBatchFullscreenModal();
      }
    },
    downloadReceipts() {
      this.transfersToDownload = this.checkedTransfers;

      this.$refs.downloadReceiptDialog.open();
    },
    async downloadAllReceipts() {
      try {
        this.transfersToDownload = await transferResource.getAllWithReceipt(
          this.initialDate,
          this.endDate,
          this.searchTerm,
          this.searchStatus,
          this.searchBank
        );

        if (this.transfersToDownload.length > 0) {
          this.$refs.downloadReceiptDialog.open();
        } else {
          this.$refs.downloadWithoutReceiptDialog.open();
        }
      } catch (error) {
        this.downloadReceiptErrorMessage = getMessage(error);

        this.$refs.downloadReceiptErrorDialog.open();
      }
    },
    async createCSVPayoutReport() {
      const isValidRange = this.validateReportRangeDate();

      if (!isValidRange) {
        this.$toaster.error({
          message: 'O intervalo de datas não pode ser maior que 31 dias.',
          duration: 5000
        });

        return;
      }

      try {
        const filters = this.formatReportFilters();

        await transferResource.createPayoutReport(filters);
        this.$toaster.success({
          message: 'Seu relatório de transferências está sendo gerado, quando concluído será enviado para o seu email.',
          duration: 10000
        });
      } catch (error) {
        this.$toaster.error(getMessage(error));
      }
    },
    validateReportRangeDate() {
      const initialDate = moment(this.initialDate).format();
      const endDate = moment(this.endDate).format();
      const diff = moment(endDate).diff(initialDate, 'days');

      if (diff > 31) {
        return false;
      }

      return true;
    },
    formatReportFilters() {
      const filters = {
        created_at__gte: moment(this.initialDate).format(),
        created_at__lte: moment(this.endDate).format()
      };

      if (this.searchTerm) {
        filters.search = this.searchTerm;
      }

      if (this.searchStatus) {
        filters.status__in = [this.searchStatus];
      }

      if (this.searchBank) {
        filters.bank_id = this.searchBank;
      }

      return filters;
    }
  }
};
</script>

<style scoped>
.transfer-pagination {
  margin-top: 40px !important;
}
</style>

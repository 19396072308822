<template>
  <bg-sidebar
    class="sidebar"
    logo-to="/"
  >
    <show-for-allowed-app :app="APPS.PAYMENTS.key">
      <bg-sidebar-group
        key="payments"
        label="Pagamentos"
        :icon="{ name: 'dollar' }"
      >
        <bg-sidebar-item
          :to="{ name: 'BatchesPage' }"
          label="Lotes"
        />
        <bg-sidebar-item
          :to="{ name: 'TransfersPage' }"
          label="Transferências"
        />
        <bg-sidebar-item
          :to="{ name: 'BilletsPage' }"
          label="Boletos"
        />
        <bg-sidebar-item
          :to="{ name: 'ReceiversPage' }"
          label="Recebedores"
        />
        <tr-hide-for-roles :roles="appRoles.ANALYST">
          <bg-sidebar-item
            :to="{ name: 'SheetImportPage' }"
            label="Importação"
          />
        </tr-hide-for-roles>
        <bg-sidebar-item
          :to="{ name: 'IndicatorPage' }"
          label="Indicadores"
        />
        <bg-sidebar-item
          :to="{ name: 'PaymentsSettings' }"
          label="Configurações"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
    <show-for-allowed-app :app="APPS.PAYMENTS.key">
      <bg-sidebar-group
        key="payins"
        label="Recebimentos"
        :icon="{ name: 'dollar-doc' }"
      >
        <bg-sidebar-item
          :to="{ name: 'PayinsPage' }"
          label="Seus recebimentos"
        />
        <show-for-feature-flag flag="card-charge">
          <bg-sidebar-item
            :to="{ name: 'CardReceivablesListPage' }"
            label="Cartão de crédito"
          />
        </show-for-feature-flag>
        <bg-sidebar-item
          :to="{ name: 'PaymentLinkListPage' }"
          label="Links de pagamento"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
    <show-for-allowed-app :app="APPS.CONTACERTA.key">
      <bg-sidebar-group
        key="contacerta"
        label="ContaCerta"
        :icon="{ name: 'user-check' }"
      >
        <tr-hide-for-roles :roles="appRoles.ANALYST">
          <bg-sidebar-item
            :to="{ name: 'ContaCertaNewValidationPage' }"
            label="Validação de dados"
          />
        </tr-hide-for-roles>
        <bg-sidebar-item
          :to="{ name: 'ContaCertaValidationHistoryPage' }"
          label="Histórico"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
    <show-for-allowed-app :app="APPS.PAYMENTS.key">
      <bg-sidebar-group
        key="contestacoes"
        label="Contestações"
        :decoration="contestationsDecoration"
        :counter="contestationCounter"
        :icon="{ name: 'doc' }"
      >
        <bg-sidebar-item
          :to="{ name: 'InfractionsPage' }"
          label="Infrações e MED"
        />
      </bg-sidebar-group>
    </show-for-allowed-app>
  </bg-sidebar>
</template>

<script setup>
import { BgSidebar, BgSidebarGroup, BgSidebarItem } from '@transfeeradev/bridge';
import appRoles from '@transfeeradev/api-enums/roles/appRoles';
import ShowForAllowedApp from '@/commons/components/container/ShowForAllowedApp.vue';
import ShowForFeatureFlag from './commons/components/container/ShowForFeatureFlag.vue';
import { APPS } from '@/commons/constants/apps';
import { getPendingInfractionReviews } from '@/apps/contestations/infractions/services/infractions';
import { useToaster } from '@/commons/composables/useToaster';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import loggedUser from '@/commons/resources/loggedUser';

const toaster = useToaster();
const contestationsDecoration = ref();
const contestationCounter = ref(0);

const fetchPendingInfractionContestations = async () => {
  if (!loggedUser.hasApplicationAccess(APPS.PAYMENTS.key)) {
    return;
  }

  try {
    const data = await getPendingInfractionReviews();
    const pendingInfractions = data?.quantity ?? 0;

    if (pendingInfractions > 0) {
      contestationsDecoration.value = 'counter';
      contestationCounter.value = pendingInfractions;
    } else {
      contestationsDecoration.value = null;
      contestationCounter.value = 0;
    }
  } catch (err) {
    contestationsDecoration.value = null;
    contestationCounter.value = 0;
    toaster.error('Ops! Ocorreu um erro ao buscar as infrações pendentes.');
  }
};

const setupPendingInfractionsRefresh = () => {
  window.addEventListener('refreshPendingInfractions', fetchPendingInfractionContestations);
};

onMounted(() => {
  fetchPendingInfractionContestations();
  setupPendingInfractionsRefresh();
});

onBeforeUnmount(() => {
  window.removeEventListener('refreshPendingInfractions', fetchPendingInfractionContestations);
});
</script>

<template>
  <div
    :class="$style.card"
    role="listitem"
  >
    <div :class="$style.header">
      <div :class="$style['icon-container']">
        <bg-icon
          size="md"
          :color="theme.iconColor"
          :name="icon"
          :class="$style.icon"
        />
      </div>
      <bg-text
        is="h5"
        color="grey-semi-dark"
        type="body-sm"
        :class="$style.label"
      >
        {{ label }}
        <bg-icon
          v-if="description"
          v-tooltip.top-center="description"
          :class="$style.icon"
          size="sm"
          color="blue-semi-dark"
          name="info-outline"
        />
      </bg-text>
    </div>
    <div :class="$style['content-container']">
      <bg-text
        color="grey-dark"
        type="h6"
        weight="bold"
      >
        {{ centsToReal(amount) }}
      </bg-text>
    </div>
    <div
      v-if="disabled"
      :class="$style.overlay"
    />
    <div
      v-if="loading"
      :class="$style.skeleton"
    >
      <summary-card-skeleton />
    </div>
  </div>
</template>

<script setup>
import SummaryCardSkeleton from './SummaryCardSkeleton.vue';
import { BgIcon, BgText } from '@transfeeradev/bridge';
import { centsToReal } from '@/commons/formatters/currency';
import { computed } from 'vue';

const props = defineProps({
  color: {
    type: String,
    required: true,
    validator: value => ['green', 'yellow', 'grey', 'red', 'blue'].includes(value)
  },
  icon: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: true
  },
  amount: {
    type: Number,
    required: true,
    default: 0
  },
  disabled: {
    type: Boolean,
    default: false
  },
  description: {
    type: String,
    default: ''
  },
  loading: {
    type: Boolean,
    default: false
  }
});

const theme = computed(() => {
  return {
    green: { backgroundColor: 'green-light', iconColor: 'green-semi-dark' },
    yellow: { backgroundColor: 'yellow-light', iconColor: 'yellow-semi-dark' },
    grey: { backgroundColor: 'grey-light', iconColor: 'grey-dark' },
    red: { backgroundColor: 'red-light', iconColor: 'red-semi-dark' },
    blue: { backgroundColor: 'blue-light', iconColor: 'blue-semi-dark' }
  }[props.color];
});
</script>

<style scoped module>
.card {
  position: relative;
  display: flex;
  border: 1px solid var(--bg-grey-light);
  border-radius: var(--bg-border-radius-md);
  background-color: var(--bg-grey-white);
  padding: var(--bg-spacing-sm);
  flex-direction: column;
  gap: var(--bg-spacing-sm);
  flex-grow: 1;
}

.content-container {
  text-align: center;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 48px;
  border-radius: var(--bg-border-radius-md);
  background-color: v-bind('`var(--bg-${theme.backgroundColor})`');
}

.label {
  display: flex;
  align-items: center;
  gap: var(--bg-spacing-2x-sm);
}

.icon {
  z-index: 1;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-grey-light);
  opacity: 0.4;
}

.skeleton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--bg-grey-white);
  padding: var(--bg-spacing-sm);
  border-radius: var(--bg-border-radius-md);
  z-index: 1;
}

.header {
  display: flex;
  gap: var(--bg-spacing-sm);
}
</style>

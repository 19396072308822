<template>
  <div>
    <template v-if="daysSinceInfraction === '-'">
      <bg-text>
        {{ daysSinceInfraction }}
      </bg-text>
    </template>
    <template v-else>
      <bg-badge
        :size="size"
        :color="getTimeColor(daysSinceInfraction).badgeColor"
      >
        <bg-text :color="getTimeColor(daysSinceInfraction).textColor">
          {{ daysSinceInfraction }}
        </bg-text>
      </bg-badge>
    </template>
  </div>
</template>

<script setup>
import moment from '@transfeeradev/moment';
import { computed } from 'vue';
import { BgText, BgBadge } from '@transfeeradev/bridge';
import { defineProps } from 'vue';

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  analysisDueDate: {
    type: String,
    required: true
  },
  size: {
    type: String,
    default: 'sm'
  }
});

const daysSinceInfraction = computed(() => {
  const infractionDueDate = moment(props.analysisDueDate);
  const remainingTime = moment.duration(infractionDueDate.diff(moment()));
  const hours = Math.floor(remainingTime.asHours());
  const minutes = remainingTime.minutes();
  return hours <= 0 ? '-' : `${hours}h${minutes}m`;
});

const timeBadgeColor = {
  yellow: { textColor: 'yellow-semi-dark', badgeColor: 'yellow-light' },
  red: { textColor: 'red-semi-dark', badgeColor: 'red-light' },
  gray: { textColor: 'grey-semi-dark', badgeColor: 'grey-light' }
};

const getTimeColor = time => {
  if (time === '-') {
    return timeBadgeColor.gray;
  }
  const hours = parseInt(time.split('h')[0], 10);
  return hours <= 24 ? timeBadgeColor.red : timeBadgeColor.yellow;
};
</script>
<template>
  <div
    class="info-action-box"
    :class="theme"
  >
    <div
      class="info-action-box-description"
      :class="type"
    >
      <bg-columns :v-align="theme === 'bridge' ? 'top' : 'center'">
        <bg-column
          width="content"
          class="icon-container"
        >
          <bg-icon
            :name="typeTheme.icon"
            size="sm"
            :color="typeTheme.color"
          />
        </bg-column>
        <bg-column width="fluid">
          <slot />
        </bg-column>
      </bg-columns>
    </div>
  </div>
</template>

<script setup>
import { BgColumn, BgColumns, BgIcon } from '@transfeeradev/bridge';
import { computed } from 'vue';

const props = defineProps({
  type: {
    type: String,
    validator: value => ['info', 'error', 'warning'].includes(value),
    required: true
  },
  theme: {
    type: String,
    validator: value => ['default', 'bridge'].includes(value),
    default: 'default'
  }
});

const typeTheme = computed(() => {
  return {
    error: {
      icon: 'alert',
      color: 'grey-white'
    },
    warning: {
      icon: 'alert',
      color: 'grey-white'
    }
  }[props.type];
});
</script>

<style scoped>
.info-action-box.bridge {
  box-shadow: none;

  .info-action-box-description {
    &.error {
      background-color: var(--bg-red-light);
      border-radius: var(--bg-border-radius-sm);
      color: var(--bg-grey-dark);

      .icon-container {
        height: 22px;
        display: flex;
        align-items: center;
      }

      svg {
        fill: var(--bg-red-semi-dark);
      }
    }
    &.warning {
      background-color: var(--bg-yellow-light);
      border-radius: var(--bg-border-radius-sm);
      color: var(--bg-grey-dark);

      .icon-container {
        height: 22px;
        display: flex;
        align-items: center;
      }

      svg {
        fill: var(--bg-yellow-semi-dark);
      }
    }
  }
}
</style>

<template>
  <tr-table :disable-click="true">
    <tr-thead>
      <tr-tr>
        <tr-th>
          <input
            id="select-all"
            v-model="selectAll"
            type="checkbox"
            class="cursor-pointer"
            @click.stop="selectAll = !selectAll"
          >
        </tr-th>
        <tr-th>E2E Pix</tr-th>
        <tr-th>Recebido em</tr-th>
        <tr-th>Prazo para resposta</tr-th>
        <tr-th>Status da constestação</tr-th>
        <tr-th>Parecer Transfeera</tr-th>
        <tr-th>Status da devolução</tr-th>
        <tr-th />
      </tr-tr>
    </tr-thead>
    <tr-tbody>
      <tr-tr
        v-for="infraction in infractionsList"
        :key="infraction.id"
      >
        <tr-td>
          <input
            v-model="infraction.selected"
            type="checkbox"
            :disabled="(infraction.analysis_status !== 'pending') || infraction.contested_at"
          >
        </tr-td>
        <tr-td>
          <!-- Id da Transação -->
          <div
            style="display: inline-flex; align-items: center; gap: 4px;"
          >
            <bg-text>
              {{ middleTruncateWithEllipsis(infraction.transaction_id, 8) }}
            </bg-text>
            <copy-clipboard-button
              type="inline"
              :text-to-copy="infraction.transaction_id"
            />
          </div>
        </tr-td>
        <tr-td>
          <!-- Data da Infração -->
          <bg-text>
            {{ formatDate(infraction.infraction_date) }}
          </bg-text>
        </tr-td>
        <tr-td>
          <!-- Tempo de Resposta -->
          <days-since-the-infraction-pill :analysis-due-date="infraction.analysis_due_date" />
        </tr-td>
        <tr-td>
          <!-- Status da Contestação -->
          <infraction-analysis-status-pill :infraction="infraction" />
        </tr-td>
        <tr-td>
          <!-- Parecer Transfeera -->
          <bg-text>
            {{ infraction.status ? infractionStatus[infraction.status] : '-' }}
          </bg-text>
        </tr-td>
        <tr-td>
          <!-- Status Devolução -->
          <bg-text>
            {{ infraction.refund.status ? refundStatus[infraction.refund.status] : '-' }}
          </bg-text>
        </tr-td>
        <tr-td class="text-center">
          <bg-button
            v-if="!((infraction.analysis_status !== 'pending') || infraction.contested_at)"
            :id="'analyze-infraction-button-' + infraction.id"
            size="sm"
            :disabled="infraction.contested_at"
            @click="openInfractionDetails(infraction)"
          >
            Analisar
          </bg-button>
          <bg-button
            v-if="(infraction.analysis_status !== 'pending') || infraction.contested_at"
            v-tooltip.top-center="'Ver mais'"
            variant="ghost"
            aria-label="Ver mais"
            @click="openInfractionDetails(infraction)"
          >
            <bg-icon
              size="sm"
              name="eye"
              color="blue-normal"
            />
          </bg-button>
          <bg-icon
            v-if="infraction.contested_at"
            v-tooltip="'Infração já contestada via e-mail'"
            size="sm"
            name="alert-outline"
            color="red-semi-dark"
            class="margin-left-10"
          />
        </tr-td>
      </tr-tr>
    </tr-tbody>
  </tr-table>
</template>

<script>
import { ref, reactive, watch } from 'vue';
import { BgButton, BgText, BgIcon } from '@transfeeradev/bridge';
import InfractionAnalysisStatusPill from '@/apps/contestations/infractions/components/InfractionAnalysisStatusPill.vue';
import DaysSinceTheInfractionPill from '@/apps/contestations/infractions/components/DaysSinceTheInfractionPill.vue';
import CopyClipboardButton from '@/commons/components/container/CopyClipboardButton.vue';
import moment from '@transfeeradev/moment';
import { middleTruncateWithEllipsis, truncateString } from '@/commons/formatters/string';
import refundStatus from '@/apps/contestations/infractions/constants/refundStatus';
import infractionStatus from '@/apps/contestations/infractions/constants/infractionStatus';

export default {
  components: {
    BgButton,
    BgText,
    CopyClipboardButton,
    InfractionAnalysisStatusPill,
    DaysSinceTheInfractionPill,
    BgIcon
  },
  props: {
    infractionsList: {
      type: Array,
      required: true
    },
    loading: Boolean
  },
  setup(props) {
    const selectAll = ref(false);
    const filters = reactive({
      status: '',
      month: '',
      search: ''
    });
    const selectedStatusFilter = ref('Status da Contestação');
    const selectedMonthFilter = ref('Mês de Recebimento da Infração');

    watch(selectAll, enabled => {
      props.infractionsList.forEach(infraction => {
        if (infraction.analysis_status === 'pending' && !infraction.contested_at) {
          infraction.selected = enabled;
        }
      });
    });

    const formatDate = date => moment(date).format('DD/MM/YYYY');

    const toggleSelectAll = () => {
      selectAll.value = !selectAll.value;
    };

    return {
      selectAll,
      filters,
      selectedStatusFilter,
      selectedMonthFilter,
      formatDate,
      toggleSelectAll,
      middleTruncateWithEllipsis,
      truncateString,
      refundStatus,
      infractionStatus
    };
  },
  methods: {
    openInfractionDetails(infraction) {
      this.$router.push({
        name: 'InfractionDetailsPage',
        params: { id: infraction.id }
      });
    }
  }
};
</script>

<style scoped>
.table-container {
  margin: auto;
  max-width: 1281px;
}

.time-ok {
  color: #ff4d4f;
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
}

.time-warning {
  font-weight: bold;
  color: #ffa500;
  padding: 5px;
  border-radius: 5px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.pagination {
  text-align: center;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
}

.response-badge {
  display: flex;
  width: 100%;
  justify-content: center;
}

.btn-flex {
  display: flex;
  justify-content: center;
}
</style>

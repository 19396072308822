import infractionsResource from '@/apps/contestations/infractions/resources/infractions';
import moment from '@transfeeradev/moment';

export async function listInfractions({
  pageCursor,
  pageSize,
  infractionStartDate,
  infractionEndDate,
  searchTerm,
  infractionAnalysisStatus
}) {
  const normalizedSearchTerm = searchTerm.replace(/[^a-zA-Z0-9]/g, ''); // remove the symbols
  const response = await infractionsResource.getInfractionsList({
    page_cursor: pageCursor,
    page_size: pageSize,
    analysis_status: Object.keys(infractionAnalysisStatus)
      .filter(key => infractionAnalysisStatus[key])
      .join(','),
    transaction_id: normalizedSearchTerm,
    payer_tax_id: normalizedSearchTerm,
    infraction_date__gte: infractionStartDate ? infractionStartDate.toISOString().split('T')[0] : null,
    infraction_date__lte: infractionEndDate ? infractionEndDate.toISOString().split('T')[0] : null
  });
  return response;
}

export async function getInfraction(infractionId) {
  const response = await infractionsResource.getInfraction(infractionId);
  return response;
}

export async function batchAnalyzeInfractions(infractionIds, analysis) {
  const response = await infractionsResource.batchAnalysis(infractionIds, analysis);
  return response;
}

export async function analyzeInfraction(infractionId, analysis) {
  const response = await infractionsResource.analyzeInfraction({
    infraction_id: infractionId,
    analysis
  });
  return response;
}

export async function getPendingInfractionReviews() {
  const response = await infractionsResource.getPendingInfractionReviews();
  return response;
}

export async function exportInfractions({
  infractionStartDate,
  infractionEndDate,
  searchTerm,
  infractionAnalysisStatus
}) {
  const response = await infractionsResource.exportInfractions({
    analysis_status__in: Object.keys(infractionAnalysisStatus)
      .filter(key => infractionAnalysisStatus[key])
      .join(','),
    search_term: searchTerm,
    infraction_date__gte: infractionStartDate ? moment(infractionStartDate).format('YYYY-MM-DD') : null,
    infraction_date__lte: infractionEndDate ? moment(infractionEndDate).format('YYYY-MM-DD') : null
  });
  return response;
}

import { appendQueryString } from '@/commons/resources/helpers';
import fetch from '@/fetch';

const BASE_URL = process.env.VUE_APP_CARD_PAYIN_URL + '/app';

export const CardReceivableStatus = {
  SCHEDULED: 'scheduled',
  RECEIVED: 'received',
  CANCELED: 'canceled',
  REFUNDED: 'refunded'
};

export const cardReceivablesResource = {
  list,
  getSummary,
  exportCSV,
  getReportById
};

/**
 * @returns {Promise<{
 *  items: CardReceivable[],
 *  metadata: {
 *    pagination: {
 *      itemsPerPage: number;
 *      totalItems: number;
 *    }
 *  }
 * }>}
 */
function list({ page = 0, page_size = 10, status__in, search, receive_date__gte, receive_date__lte } = {}) {
  const query = buildFiltersQuery({
    page,
    page_size,
    status__in,
    search,
    receive_date__gte,
    receive_date__lte
  });

  return fetch(`${BASE_URL}/receivables?${query}`);
}

/**
 * @returns {Promise<CardReceivablesSummary>}
 */
function getSummary({ page = 0, page_size = 10, status__in, search, receive_date__gte, receive_date__lte } = {}) {
  const query = buildFiltersQuery({
    page,
    page_size,
    status__in,
    search,
    receive_date__gte,
    receive_date__lte
  });

  return fetch(`${BASE_URL}/receivables_summary?${query}`);
}

function buildFiltersQuery({
  page = 0,
  page_size = 10,
  status__in,
  search,
  receive_date__gte,
  receive_date__lte
} = {}) {
  let query = new URLSearchParams({ page, page_size });

  query = appendQueryString(query, { search, receive_date__gte, receive_date__lte });

  if (status__in) {
    query = appendQueryString(query, { status__in });
  }

  return query;
}

function exportCSV({ receive_date__gte, receive_date__lte, search, status__in } = {}) {
  return fetch(`${BASE_URL}/card_receivables_reports`, {
    method: 'POST',
    body: JSON.stringify({
      receive_date__gte,
      receive_date__lte,
      search,
      status__in: status__in?.length ? status__in : undefined
    })
  });
}

function getReportById(id) {
  return fetch(`${BASE_URL}/card_receivables_reports/${id}`);
}
